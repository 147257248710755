@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

.tooltip .ant-tooltip-inner {
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  padding: 4px;
  line-height: normal;
  min-height: 24px;
  background-color: white;
  color: #1e143b;
  cursor: default;
  text-align: center;
}

.tooltip .ant-tooltip-arrow {
  --antd-arrow-background-color: white;
}

.bgContainer {
  position: absolute;
  z-index: -1;
  margin: 0 auto;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  filter: blur(150px);
  max-width: 1440px;
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: visible !important;

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .bgContainer {
    filter: blur(100px);
  }
}

.bgContainer::-webkit-scrollbar {
  display: none !important;
}

.bgColor {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .bgContainer {
    filter: blur(0);
  }
}
